import { useDispatch, useSelector } from "react-redux";
import { completeAnimation, ANIMATION_TYPES } from "store/slices/animations";
import { ReactComponent as LeftSide } from "assets/images/main_middle/culture/mid_culture_responsive-left.svg";
import { ReactComponent as RightSide } from "assets/images/main_middle/culture/mid_culture_responsive-right.svg";
import * as captainAnimation from "assets/animations/mid_culture_captain.json"
import * as jugglerAnimation from "assets/animations/mid_culture_juggler.json"
import * as shipAnimation from "assets/animations/mid_culture_ship.json"
import rootStyles from "../Sections.module.scss"
import styles from "./Culture.module.scss"
import LottiePlayer from "../LottiePlayer";

function Culture({ animatedIn, animatedOut }) {
  const activeAnimation = useSelector(state => state.animations.activeAnimation)
  const dispatch = useDispatch()

  return (
    <div className={`
      ${rootStyles.section} 
      ${rootStyles.sectionCulture} 
      ${styles.container}
      ${animatedIn ? styles.animateIn : ''} 
      ${animatedOut ? styles.animateOut : ''}
    `}>
      <LeftSide className={styles.leftSide} />
      <RightSide className={styles.rightSide} />

      <LottiePlayer animationData={captainAnimation} 
        className={styles.captainAnimation} 
        width={150} 
        height={150} 
        onLoopComplete={() => {
          if (!animatedIn) dispatch(completeAnimation(ANIMATION_TYPES.captain))
        }}
        loopsNumber={3}
        playing={activeAnimation.indexOf(ANIMATION_TYPES.captain) !== -1 || animatedIn}
      />

      <LottiePlayer animationData={jugglerAnimation} 
        className={styles.jugglerAnimation} 
        width={260} 
        height={260}
        onLoopComplete={() => {
          if (!animatedIn) dispatch(completeAnimation(ANIMATION_TYPES.juggler))
        }}
        playing={activeAnimation.indexOf(ANIMATION_TYPES.juggler) !== -1 || animatedIn}
      />

      <LottiePlayer animationData={shipAnimation} 
        className={styles.shipAnimation} 
        width={275} 
        height={275} 
        onLoopComplete={() => {
          if (!animatedIn) dispatch(completeAnimation(ANIMATION_TYPES.ship))
        }}
        loopsNumber={1}
        playing={activeAnimation.indexOf(ANIMATION_TYPES.ship) !== -1 || animatedIn}
      />
    </div>
  );
}

export default Culture