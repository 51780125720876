import { useMsal } from "@azure/msal-react";
import styles from "./Intro.module.scss"
import { ReactComponent as LogoLarge } from 'assets/images/franke-logo-large.svg'
import { ReactComponent as LogoFooter } from 'assets/images/franke-logo-footer.svg'
import { ReactComponent as ArrowRight } from 'assets/images/icons/arrow.svg'
import * as introAnimation from 'assets/animations/intro_world.json'
import { loginRequest } from "authConfig";
import { intro } from "config/texts"
import LottiePlayer from 'components/MainArea/Sections/LottiePlayer'
import { useNavigate } from 'react-router-dom';

function LoginButton() {
  const navigate = useNavigate()
  const { instance } = useMsal();
  function handleLogin(instance) {
    instance.loginPopup(loginRequest)
      .then((...args) => {
        navigate('/app/home')
      })
      .catch(e => {
        console.error(e);
      });
  }
  return <button onClick={() => handleLogin(instance)}
    className={styles.cta}><span>{intro.cta}</span> <ArrowRight className={styles.ctaArrow} /></button>
}

function Intro() {
  return (
    <div className={styles.page}>
      <div className={styles.content}>
        <LogoLarge className={styles.logoLarge} />
        <h1 className={styles.title}>
          {intro.title}
        </h1>
        <p className={styles.text}>
          {intro.text}
          <strong>{intro.textBold}</strong>
        </p>
        <LoginButton />
        <LogoFooter className={styles.logoFooter} />
      </div>
      {/*<IntroPic className={styles.introPic} />*/}
      <LottiePlayer playing animationData={introAnimation} width={700} height={700} className={styles.introAnimation} />
    </div>
  );
}

export default Intro