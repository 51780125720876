import { BrowserRouter, Routes, Route } from "react-router-dom";

import Intro from './pages/Intro/Intro'
import Outro from './pages/Intro/Outro'
import Home from "./pages/Home";
import ProtectedRoute from './pages/ProtectedRoute'


function Router() {
  return (  
    <BrowserRouter basename="/">
      <Routes>
        <Route exact path="/" element={<Intro />} />
        <Route exact path="/outro" element={<Outro />} />
        <Route exact path="/app" element={<ProtectedRoute />}>
          <Route exact path="/app/home" element={<Home />} />
          <Route exact path="/app/one/:section" element={<Home />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Router;