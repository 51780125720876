import { configureStore } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import ui from './slices/ui'
import animations from './slices/animations'

export const store = configureStore({
  reducer: {
    ui,
    animations
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
})