import { useRef, useState } from 'react';
import Lottie from 'react-lottie';

function LottiePlayer({ 
  width,
  height, 
  animationData, 
  className, 
  onLoopComplete,
  playing = false,
  loopsNumber = 2
}) {
  const [loopsCompleted, setLoopsCompleted] = useState(0)
  const loopsRef = useRef()

  const defaultOptions = {
    loop: true,
    autoplay: playing,
    animationData: animationData,
  };

  loopsRef.current = loopsCompleted

  return <div className={className}>
    <Lottie options={defaultOptions}
      height={height}
      width={width}
      isStopped={!playing}
      isPaused={false}
      className={className}
      eventListeners={[
        {
          eventName: 'complete',
          callback: () => {
            
          }
        },
        {
          eventName: 'loopComplete',
          callback: () => {
            if (loopsRef.current === (loopsNumber - 1) && onLoopComplete) {
              onLoopComplete()
              setLoopsCompleted(0)
            } else {
              setLoopsCompleted(loopsRef.current + 1)
            }
          }
        }
      ]} />
    </div>
}

export default LottiePlayer