import Icon from "components/Icon/Icon"
import styles from "./Tooltips.module.scss"

const MONTH_MAP = [
  'january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'
]

function MilestoneTooltip({ title, text, color }) {
  const dateString = text[0].split(' ')
  const date = new Date()
  const today = new Date().getTime()

  if (dateString.length === 2) {
    date.setFullYear(dateString[1])
    date.setMonth(MONTH_MAP.indexOf(dateString[0].toLowerCase()), 1)
  } else {
    date.setFullYear(dateString[0], 0, 1)
  }

  const isPast = today > date.getTime()
  
  return <div className={`
    ${styles.wrap} 
    ${styles.wrapMilestone} 
    ${styles[`wrap_${color}`]}
  `}>
    <h4 className={styles.title}>
      <span>{title}</span>
      {isPast && <Icon type="check" />}
    </h4>
    
    {text.map((t, i) => {
      return <p key={`text-${i}`} className={styles.text}>
        {(i === 0) ? <i>{t}</i> : t}
      </p>
    })}
  </div>
}

export default MilestoneTooltip;