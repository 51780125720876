const MODAL_TYPES = {
  "brand": "brand",
  "culture": "culture",
  "planet": "planet",
  "strategy": "strategy",
  "value_behaviour": "value_behaviour",
  "sustainability": "sustainability",
  "roadmap": "roadmap",
  "employee_journeymap": "employee_journeymap"
}

const MODALS = {
  [MODAL_TYPES.brand]: "/docs/Franke_Brand_Poster_EN_final.pdf",
  [MODAL_TYPES.culture]: "/docs/Franke_Culture_Poster_EN_final.pdf",
  [MODAL_TYPES.planet]: "/docs/Franke_Planet_Poster_EN_final.pdf",
  [MODAL_TYPES.strategy]: "/docs/Franke_Strategy_Poster_EN_final.pdf",
  [MODAL_TYPES.value_behaviour]: "/docs/Franke_Value_Brochure_EN_final.pdf",
  [MODAL_TYPES.sustainability]: "/docs/sustainability-report-2020-en.pdf",
  [MODAL_TYPES.roadmap]: "/docs/Activation_Program.pdf",
  [MODAL_TYPES.employee_journeymap]: "/docs/employee_journey_map_quer-final.pdf"
};

export { MODALS, MODAL_TYPES }