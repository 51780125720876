import GoalItem from "components/SidebarGoals/GoalItem";
import styles from "./Tooltips.module.scss"

function GoalTooltip({ title, icon, list, isEmissions = false }) {
  return <div className={`${styles.wrap} ${styles.wrapGoal}`}>
    <div className={styles.goal}>
      <GoalItem icon={icon} label={title} isEmissions={isEmissions} showPlus={false} />
    </div>
    
    <h4 className={styles.title}>{title}</h4>
    <ul className={styles.textList}>{list.map(l => {
      return <li>
        {l.label}
        {l.subPoints && <ul>{l.subPoints.map(sp => <li>{sp}</li>)}</ul>}
      </li>
    })}</ul>
  </div>
}

export default GoalTooltip;