import { ReactComponent as Pin } from 'assets/images/pin.svg'
import { ReactComponent as Cloud } from 'assets/images/cloud.svg'
import { ReactComponent as LogoFooter } from 'assets/images/franke-logo-footer.svg'
import { ReactComponent as World } from 'assets/images/sidebars/sidebar_right_world.svg'

import { ReactComponent as Emissions } from 'assets/images/icons/goal/emissions.svg'
import { ReactComponent as OneBrand } from 'assets/images/icons/goal/one-brand.svg'
import { ReactComponent as Profit } from 'assets/images/icons/goal/profit.svg'
import { ReactComponent as Revenue } from 'assets/images/icons/goal/revenue.svg'
import { ReactComponent as Accidents } from 'assets/images/icons/goal/accidents.svg'

import styles from "./SidebarGoals.module.scss"
import { goals } from "config/texts"
import IconLink from 'components/IconLink/IconLink'
import GoalItem from './GoalItem'
import GoalTooltip from 'components/Tooltips/Goal'
import goalsTooltips from 'config/goals'

function SidebarGoals() {
  return (
    <div className={styles.wrap}>
      <h4 className={styles.title}>
        <Pin />
        <span>{goals.title}</span>
        <Cloud />
      </h4>
      
      <div className={styles.goals}>
        <span className={styles.goalsTitle}>Until 2025:</span>
        <GoalItem icon={<OneBrand />} 
          label={"1 FRANKE"} 
          tooltip={<GoalTooltip icon={<OneBrand />} title="1 FRANKE" list={goalsTooltips["1franke"].list} />} />

        <GoalItem icon={<Revenue />} 
          label={"3 BILLION IN REVENUE"} 
          tooltip={<GoalTooltip icon={<Revenue />} title="3 BILLION IN REVENUE" list={goalsTooltips["revenue"].list} />} />
        
        <GoalItem icon={<Profit />} 
          label={"300 MILLION IN PROFIT"} 
          tooltip={<GoalTooltip icon={<Profit />} title="300 MILLION IN PROFIT" list={goalsTooltips["profit"].list} />} />

        <GoalItem icon={<Accidents />} 
          label={"0 ACCIDENTS"} 
          tooltip={<GoalTooltip icon={<Accidents />} title="0 ACCIDENTS" list={goalsTooltips["accidents"].list} />} />

        <span className={`${styles.goalsTitle} ${styles.goalsTitleSecond}`}>Until 2030 and 2040:</span>
        <GoalItem icon={<Emissions />} 
          label={"NET 0 EMISSIONS"} 
          tooltip={<GoalTooltip icon={<Emissions />} title="NET 0 EMISSIONS" list={goalsTooltips["emissions"].list} />} />
      </div>
      
      <World className={styles.world} />

      <div className={styles.content}>
        <IconLink to="/outro" iconAlign="right" size="large" className={styles.next} icon="arrow" label={goals.text} />
      </div>

      <LogoFooter className={styles.logoFooter} />
      
    </div>
  );
}

export default SidebarGoals