import SectionsList from "./SectionsList";
import styles from "./MainArea.module.scss";
import Sections from "./Sections/Sections";
import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import Brand from "pages/One/Brand";
import Planet from "pages/One/Planet";
import Strategy from "pages/One/Strategy";
import Culture from "pages/One/Culture";
import { CSSTransition } from "react-transition-group";
import { useDispatch, useSelector } from "react-redux";
import { startFirstAnimation, startSecondAnimation } from "store/slices/animations";

function SectionContent({ section }) {
  switch (section) {
    case 'brand': 
      return <Brand />
    case 'planet':
      return <Planet />
    case 'culture':
      return <Culture />
    case 'strategy':
      return <Strategy />
    default: 
      return null;
  }
}

function MainArea() {
  const params = useParams()
  const dispatch = useDispatch()
  const [animationsEnded, setAnimationsEnded] = useState(false)
  const sectionRef = useRef(params.section)
  const activeAnimation = useSelector(state => state.animations.activeAnimation)

  useEffect(() => {
    if (params.section) {
      sectionRef.current = params.section
    }

    if (!params.section && !activeAnimation.length) {
      dispatch(startFirstAnimation())
      setTimeout(() => {
        dispatch(startSecondAnimation())
      }, 2000)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.section])

  return (
    <div className={`${styles.wrap} ${params.section ? styles.wrapSection : ''}`}>
      <div className={styles.wrapInner}>
        <CSSTransition
          in={!!(animationsEnded && params.section)}
          timeout={500}
          classNames="content"
          unmountOnExit
          >
          <div><SectionContent section={sectionRef.current} /></div>
        </CSSTransition>
    
        <SectionsList />
        <Sections onExit={() => {
          setAnimationsEnded(true)
        }} onEnter={() => {
          setAnimationsEnded(false)
        }} />
      </div>
    </div>
  );
}

export default MainArea