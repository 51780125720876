import { useDispatch, useSelector } from "react-redux";
import { ANIMATION_TYPES, completeAnimation } from "store/slices/animations";

import { ReactComponent as LeftSide } from "assets/images/main_middle/strategy/mid_strategy_responsive-left.svg"
import { ReactComponent as RightSide } from "assets/images/main_middle/strategy/mid_strategy_responsive-right.svg"
import * as dronesAnimation from "assets/animations/mid_strategy_drones.json"
import * as walfischAnimation from "assets/animations/mid_strategy_walfisch2.json"
import * as houseAnimation from "assets/animations/house_new.json"
import rootStyles from "../Sections.module.scss"
import styles from "./Strategy.module.scss"
import LottiePlayer from "../LottiePlayer"

function Strategy({ animatedIn, animatedOut }) {
  const activeAnimation = useSelector(state => state.animations.activeAnimation)
  const dispatch = useDispatch()

  return (
    <div className={`
      ${rootStyles.section} 
      ${rootStyles.sectionStrategy} 
      ${styles.container}
      ${animatedIn ? styles.animateIn : ''} 
      ${animatedOut ? styles.animateOut : ''}
    `}>
      <LeftSide className={styles.leftSide} />
      <RightSide className={styles.rightSide} />
      
      <LottiePlayer animationData={dronesAnimation} 
        className={styles.dronesAnimation} 
        width={350} 
        height={350}
        onLoopComplete={() => {
          if (!animatedIn) dispatch(completeAnimation(ANIMATION_TYPES.drones))
        }}
        loopsNumber={3}
        playing={activeAnimation.indexOf(ANIMATION_TYPES.drones) !== -1 || animatedIn} 
      />

      <LottiePlayer animationData={walfischAnimation} 
        className={styles.walfischAnimation} 
        width={300} 
        height={300} 
        onLoopComplete={() => {
          if (!animatedIn) dispatch(completeAnimation(ANIMATION_TYPES.walfisch))
        }}
        playing={activeAnimation.indexOf(ANIMATION_TYPES.walfisch) !== -1 || animatedIn}
      />

      <LottiePlayer 
        animationData={houseAnimation} 
        className={styles.houseAnimation} 
        width={290}
        height={290}
        onLoopComplete={() => {
          if (!animatedIn) dispatch(completeAnimation(ANIMATION_TYPES.house))
        }}
        playing={activeAnimation.indexOf(ANIMATION_TYPES.house) !== -1 || animatedIn}
      />
    </div>
  );
}

export default Strategy