//import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Router from './Router'
import Modal from 'components/Modals/ModalUI'
import ModalPDF from 'components/Modals/ModalPDF'
import ModalVideo from 'components/Modals/ModalVideo'
import ModalVideoCulture from 'components/Modals/ModalVideoCulture'
import ModalVideoImageMovie from 'components/Modals/ModalVideoImageMovie'
import { toggleVideoModal, togglePDFModal } from 'store/slices/ui'
import './App.scss';

function App() {
  //const [passed, setPassed] = useState(true)
  const dispatch = useDispatch()
  const videoModal = useSelector(state => state.ui.videoModal)
  const sectionModal = useSelector(state => state.ui.sectionModal)

  /*useEffect(() => {
    const pass = window.prompt('Password')
    if (pass === 'franke2022') {
      setPassed(true)
    } else {
      setPassed(false)
    }
  }, [])*/

  return <div className="app">
    <Router />
    {sectionModal.state && <Modal onClose={() => {
      dispatch(togglePDFModal({ state: false }))
    }} name="sectionModal" modalType={sectionModal.type}>
      <ModalPDF type={sectionModal.type} />
    </Modal>}

    {videoModal.state && <Modal onClose={() => {
      dispatch(toggleVideoModal({ state: false }))
    }} name="videoModal">
      {!videoModal.type && <ModalVideo />}
      {videoModal.type === 'culture' && <ModalVideoCulture />}
      {videoModal.type === 'image-movie' && <ModalVideoImageMovie />}
    </Modal>}
  </div>;
}

export default App;