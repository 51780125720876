import { useDispatch } from 'react-redux'
import styles from "./Intro.module.scss"
import { ReactComponent as LogoLarge } from 'assets/images/franke-logo-large.svg'
import { ReactComponent as LogoFooter } from 'assets/images/franke-logo-footer.svg'
import { outro } from "config/texts"
import Links from "components/Links/Links"
import LottiePlayer from 'components/MainArea/Sections/LottiePlayer'
import * as outroAnimation from 'assets/animations/outro_world.json'
import IconLink from 'components/IconLink/IconLink'
import { togglePDFModal } from 'store/slices/ui'
import { MODAL_TYPES } from 'config/modals'

function Outro() {
  const dispatch = useDispatch()

  return (
    <div className={styles.page}>
      <div className={styles.content}>
        <LogoLarge className={styles.logoLarge} />
        <h1 className={styles.title}>
          {outro.title}
        </h1>
        <p className={styles.text}>
          {outro.text}
        </p>
        <IconLink label="Our Activation Roadmap" 
          icon={"doc"} 
          size="large" 
          onClick={() => dispatch(togglePDFModal({ state: true, type: MODAL_TYPES.roadmap }))}
          className={styles.roadmap} 
        />
        <Links iconAlign="right" list={[
          { to: "/", label: "Landing Page", icon: "arrow-left" },
          { to: -1, label: "Back", icon: "arrow-left" },
        ]} />
        <LogoFooter className={`${styles.logoFooter} ${styles.logoFooterOutro}`} />
      </div>
      
      <LottiePlayer playing animationData={outroAnimation} width={620} height={620} />
    </div>
  );
}

export default Outro