import { ReactComponent as Triangle } from 'assets/images/triangle-right.svg'
import { ReactComponent as Marker } from 'assets/images/milestone-marker.svg'
import styles from "./Milestones.module.scss"
import Icon from 'components/Icon/Icon';
import { useEffect, useState } from 'react';
import Tooltip from 'components/TooltipUI/TooltipUI';
import MilestoneTooltip from 'components/Tooltips/Milestone';
import milestonesTexts from 'config/milestones';
import { useParams } from 'react-router-dom';

function Milestone({ color = "blue", className, onTooltipToggle, milestoneName, inverseTooltip = false, activeTooltip }) {
  const [tooltipActive, setTooltipActive] = useState(false)

  useEffect(() => {
    onTooltipToggle(tooltipActive, milestoneName)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tooltipActive])

  useEffect(() => {
    if (activeTooltip && activeTooltip !== milestoneName && tooltipActive) {
      setTooltipActive(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTooltip, milestoneName])

  return <div className={`${styles.milestone} ${className} ${tooltipActive ? styles.milestoneActive : ''}`}>
    <Marker className={`${styles.marker} ${styles[`marker_${color}`]} ${tooltipActive ? styles.markerActive : ''}`} />
    <span onClick={() => setTooltipActive(true)}>
      <Icon type="plus2" 
        className={`${styles.markerPlusIcon} ${tooltipActive ? styles.markerPlusIconActive : ''}`}
        highlight={tooltipActive} 
      />
    </span>
    {tooltipActive && <Tooltip name="tooltipMilestone" onClose={() => setTooltipActive(false)} inverseTooltip={inverseTooltip}>
      <MilestoneTooltip {...milestonesTexts[milestoneName]} color={color} />
    </Tooltip>}
  </div>
}

function TimePeriod({ milestones, showNow, year, onTooltipOpen, activeTooltip }) {
  const [tooltipShown, setTooltipShown] = useState(false)
  const month = new Date().getMonth() + 1

  return <div className={`${styles.period} ${tooltipShown ? styles.periodActive : ""}`}>
    <span className={styles.year}>{year}</span>
    {showNow && <span className={`${styles.nowCircle} ${styles[`nowCircle_${month}`]}`} />}
    {milestones.map((m, i) => (
      <Milestone key={`milestone-${year}-${i}`} 
        {...m}
        activeTooltip={activeTooltip}
        onTooltipToggle={(v, name) => {
          setTooltipShown(v)
          if (v) {
            onTooltipOpen(name)
          } else {
            onTooltipOpen(null)
          }
        }}
      />
      )
    )}
  </div>
}

function Milestones() {
  const [activeTooltip, setActiveTooltip] = useState(null)
  const params = useParams()
  const year = new Date().getFullYear()
  
  function getVisibleMilestones(list) {
    if (params.section) {
      return list.filter(i => i.section === params.section)
    }
    return list;
  }

  return (
    <div className={styles.wrap}>
      <span className={styles.title}>OUR MILESTONES</span>
      <span className={styles.tbc}>...</span>
      <div className={styles.timeline}>
      
        <span className={styles.period}>
          <span className={`${styles.year} ${styles.yearFirst}`}>1911</span>
        </span>

        <TimePeriod year="2014" 
          milestones={getVisibleMilestones([{ className: styles.m8, milestoneName: "2014_1", color: "red", inverseTooltip: true, section: "brand" }])}
          onTooltipOpen={(v) => setActiveTooltip(v)}
          activeTooltip={activeTooltip} />

        <TimePeriod year="2020" 
          milestones={getVisibleMilestones([{ className: styles.m8, milestoneName: "2020", color: "blue", section: "strategy" }])} 
          onTooltipOpen={(v) => setActiveTooltip(v)}
          activeTooltip={activeTooltip} />

        <TimePeriod year="2021" 
          milestones={getVisibleMilestones([{ className: styles.m8, milestoneName: "2021", color: "blue", section: "strategy" }])}
          onTooltipOpen={(v) => setActiveTooltip(v)}
          activeTooltip={activeTooltip} />

        <TimePeriod year="2022" milestones={getVisibleMilestones([
            { className: styles.m1, color: "blue", milestoneName: "2022_strategy", section: "strategy" },
            { className: styles.m5, color: "orange", milestoneName: "2022_culture", section: "culture" },
            { className: styles.m9, color: "green", milestoneName: "2022_planet_1", section: "planet" },
            { className: styles.m12, color: "green", milestoneName: "2022_planet_2", section: "planet" },
            { className: styles.m8, color: "red", milestoneName: "2022_brand", section: "brand" }
          ])} 
          showNow={year === 2022}
          onTooltipOpen={(v) => setActiveTooltip(v)}
          activeTooltip={activeTooltip} />
        
        <TimePeriod year="2023" milestones={getVisibleMilestones([
            { className: styles.m1, color: "blue", milestoneName: "2023_strategy", section: "strategy" },
            { className: styles.m2, color: "orange", milestoneName: "2023_culture", section: "culture" },
            { className: styles.m7, color: "green", milestoneName: "2023_planet", section: "planet" },
          ])} 
          showNow={year === 2023}
          onTooltipOpen={(v) => setActiveTooltip(v)}
          activeTooltip={activeTooltip} />

        <TimePeriod year="2024" milestones={getVisibleMilestones([
            { className: styles.m1, color: "orange", milestoneName: "2024_culture", section: "culture" },
            { className: styles.m8, color: "blue", milestoneName: "2024_strategy", section: "strategy" },
          ])} 
          showNow={year === 2024} 
          onTooltipOpen={(v) => setActiveTooltip(v)}
          activeTooltip={activeTooltip}
        />
        
        <span className={styles.period}>
          <span className={styles.year}>2025</span>
        </span>
        
        <span className={styles.period}>
          <span className={styles.year}>2030</span>
        </span>
      </div>
      
      <div className={styles.bar}>
        <span className={styles.startIcon} />
        <span className={styles.endIcon}><Triangle /></span>
      </div>
    </div>
  );
}

export default Milestones