import Icon from 'components/Icon/Icon';
import IconLink from 'components/IconLink/IconLink';
import { Link } from 'react-router-dom';
import styles from "./One.module.scss"
import { useDispatch } from 'react-redux';
import { togglePDFModal } from 'store/slices/ui';
import { MODAL_TYPES } from 'config/modals';

function Strategy() {
  const dispatch = useDispatch()
  return (
    <div className={`${styles.wrap} ${styles.wrapStrategy}`}>
      <div className={styles.leftCol}>
        <h5 className={`${styles.title} ${styles.titleStrategy}`}>
          <Link to="/app/home" className={styles.back}>
            <Icon type="arrow-left" />
          </Link>
          <span>ONE STRATEGY</span>
        </h5>

        <p className={styles.text}>
          Franke continues its journey towards One Franke and focuses on these four topics:
        </p>

        <h6 className={`${styles.subTitle} ${styles.subTitleStrategy}`}>Top 3 Positioning</h6>
        <p className={styles.text}>
          To fully exploit its potential and be among the top 3 players globally, Franke must focus on business areas where we are global leaders and on opportunities that really make a difference.
        </p>

        <h6 className={`${styles.subTitle} ${styles.subTitleStrategy}`}>Global alignment</h6>
        <p className={styles.text}>
          In order to optimize costs, learning, and speed, Franke harmonizes its common business processes, gets rid of redundancies and makes sure each division and region gets to focus on its strengths.
        </p>
      </div>

      <div className={styles.rightCol}>
        <h6 className={`${styles.subTitle} ${styles.subTitleStrategy}`}>Customer and consumer centricity</h6>
        <p className={styles.text}>
          In order to fulfill the customer's and consumer's needs, Franke's investing into foresight capabilities related to trends, markets and technologies. 
        </p>

        <h6 className={`${styles.subTitle} ${styles.subTitleStrategy}`}>Innovation and adaptiveness</h6>
        <p className={styles.text}>
          To keep up with a world in constant motion, Franke will keep a flexible mind and regularly aligns itself with its ever-changing surroundings.
        </p>

        <div className={`${styles.links} ${styles.linksRightAlign}`}>
          <span className={styles.linksTitle}>Have a look at the following material:</span>
          <div className={styles.linksContainer}>
            <IconLink icon={"doc"} label="One Strategy Poster" size="small" onClick={() => {
              dispatch(togglePDFModal({ state: true, type: MODAL_TYPES.strategy }))
            }} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Strategy