import Icon from "components/Icon/Icon";
import { Link } from "react-router-dom";
import styles from "./IconLink.module.scss"

function IconLink({
  to, 
  className = '', 
  iconAlign = 'left', 
  label, 
  icon,
  onClick,
  highlightIcon = false,
  size = ""
}) {
  const classList = `
    ${className} 
    ${styles.link} 
    ${styles[`link_${iconAlign}`]}
    ${size === "small" ? styles.linkSmall : ''}
    ${size === "large" ? styles.linkLarge : ''}
  `

  function Content() {
    return <>
      <Icon type={icon} className={`${styles.icon} ${styles[`icon_${icon}`]}`} highlight={highlightIcon} />
      <span>{label}</span>
    </>;
  }

  return (to ? 
    <Link to={to} className={classList}>
    <Content />
  </Link> : <div className={classList} onClick={() => onClick()}>
    <Content />
  </div>
  )
}

export default IconLink;