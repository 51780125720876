import { createSlice } from '@reduxjs/toolkit'

const ANIMATION_TYPES = {
  plane: 'plane',
  battery: 'battery',
  walfisch: 'walfisch',
  city: 'city',
  drones: 'drones',
  captain: 'captain',
  umbrella: 'umbrella',
  car: 'car',
  ship: 'ship',
  house: 'house',
  juggler: 'juggler' 
}

const ANIMATIONS_QUEUE = [
  ANIMATION_TYPES.plane,
  ANIMATION_TYPES.battery,
  ANIMATION_TYPES.walfisch,
  ANIMATION_TYPES.city,
  ANIMATION_TYPES.drones,
  ANIMATION_TYPES.captain,
  ANIMATION_TYPES.umbrella,
  ANIMATION_TYPES.car,
  ANIMATION_TYPES.ship,
  ANIMATION_TYPES.house,
  ANIMATION_TYPES.juggler
]

const initialState = {
  activeAnimation: []
}

export const animations = createSlice({
  name: 'animations',
  initialState,
  reducers: {
    startFirstAnimation(state) {
      state.activeAnimation.push(ANIMATIONS_QUEUE[0])
    },
    startSecondAnimation(state) {
      state.activeAnimation.push(ANIMATIONS_QUEUE[1])
    },
    completeAnimation(state, action) {
      const animationName = action.payload
      const activeIndex = ANIMATIONS_QUEUE.indexOf(animationName)
      let newIndex = activeIndex
      const currentIndex = state.activeAnimation.indexOf(animationName)

      if (activeIndex === ANIMATIONS_QUEUE.length - 1) {
        newIndex = 1
      } else if (activeIndex === ANIMATIONS_QUEUE.length - 2) {
        newIndex = 0
      } else {
        newIndex = activeIndex + 2;
      }
      state.activeAnimation[currentIndex] = ANIMATIONS_QUEUE[newIndex]
    }
  },
})

const { completeAnimation, startFirstAnimation, startSecondAnimation } = animations.actions
export default animations.reducer
export { completeAnimation, startFirstAnimation, startSecondAnimation, ANIMATION_TYPES }