import { useDispatch, useSelector } from "react-redux";
import { ANIMATION_TYPES, completeAnimation } from "store/slices/animations";

import { ReactComponent as LeftSide } from "assets/images/main_middle/planet/mid_planet_responsive-left.svg";
import { ReactComponent as RightSide } from "assets/images/main_middle/planet/mid_planet_responsive-right.svg";
import * as carAnimation from "assets/animations/mid_planet_car.json"
import * as cityAnimation from "assets/animations/mid_planet_city_2.json"
import * as batteryAnimation from "assets/animations/mid_planet_battery.json"

import rootStyles from "../Sections.module.scss"
import styles from "./Planet.module.scss"
import LottiePlayer from "../LottiePlayer";


function Planet({ animatedIn, animatedOut }) {
  const activeAnimation = useSelector(state => state.animations.activeAnimation)
  const dispatch = useDispatch()

  return (
    <div className={`
      ${rootStyles.section} 
      ${rootStyles.sectionPlanet} 
      ${styles.container}
      ${animatedIn ? styles.animateIn : ''} 
      ${animatedOut ? styles.animateOut : ''}
    `}>
      <LeftSide className={styles.leftSide} />
      <RightSide className={styles.rightSide} />

      <LottiePlayer animationData={carAnimation} 
        className={styles.carAnimation} 
        width={280} 
        height={280} 
        onLoopComplete={() => {
          if (!animatedIn) dispatch(completeAnimation(ANIMATION_TYPES.car))
        }}
        playing={activeAnimation.indexOf(ANIMATION_TYPES.car) !== -1 || animatedIn}
      />
      
      <LottiePlayer animationData={cityAnimation} 
        className={styles.cityAnimation} 
        width={300} 
        height={300}
        onLoopComplete={() => {
          if (!animatedIn) dispatch(completeAnimation(ANIMATION_TYPES.city))
        }}
        playing={activeAnimation.indexOf(ANIMATION_TYPES.city) !== -1 || animatedIn}
      />

      <LottiePlayer animationData={batteryAnimation} 
        className={styles.batteryAnimation}
        width={220}
        height={220} 
        onLoopComplete={() => {
          if (!animatedIn) dispatch(completeAnimation(ANIMATION_TYPES.battery))
        }}
        loopsNumber={1}
        playing={activeAnimation.indexOf(ANIMATION_TYPES.battery) !== -1 || animatedIn}
      />
    </div>
  );
}

export default Planet