import { ReactComponent as LogoLarge } from 'assets/images/franke-logo-large.svg'
import { ReactComponent as World } from 'assets/images/sidebars/sidebar_left_world.svg'
import styles from "./SidebarJourney.module.scss"
import { journey } from "config/texts"
import Links from '../Links/Links'
import IconLink from 'components/IconLink/IconLink'
import ImpressumTooltip from 'components/Tooltips/Impressum'
import { useDispatch } from 'react-redux'
import { toggleVideoModal } from 'store/slices/ui'
import { Link } from 'react-router-dom'

function SidebarJourney() {
  const dispatch = useDispatch()
  
  return (
    <div className={styles.wrap}>
      <div className={styles.content}>
        <Link to="/app/home"><LogoLarge className={styles.logoLarge} /></Link>
        <h4 className={styles.title}>{journey.title}</h4>
        <p className={styles.text}>
          {journey.text}
        </p>
        <IconLink onClick={() => {
          dispatch(toggleVideoModal({ state: true }))
        }} className={styles.play} icon="play" label="Our Journey in a nutshell" />
      </div>
      
      <World className={styles.world} />
      
      <div className={styles.content}>
        <Links iconAlign="right" list={[ 
          { to: "/", label: "Landing Page", icon: "arrow-left" },
          { label: "Impressum", icon: "plus", tooltip: <ImpressumTooltip />, tooltipName: "tooltipImpressum" }
        ]} className={styles.links} size="large" />
      </div>
    </div>
  );
}

export default SidebarJourney