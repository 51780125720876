const intro = {
  title: `Welcome to
  the One Franke
  Journey`,
  text: "As a company we are in constant motion. We have a clear vision of what we want to become and a concrete plan on how to achieve it.",
  textBold: "Are you ready to join us on that journey?",
  cta: "LET'S START"
}

const outro = {
  title: `let's open up
  a world of
  opportunities
  together.`,
  text: `This is what we will work on in the coming years - each and every one and together.  
  Let's make use of constant change and take advantage of emerging possibilities.`
}

const journey = {
  title: 'Franke wants to transform change into opportunities to shape a better living',
  text: `This means: Franke embraces what our ever-changing world has to offer and opens up a world of opportunities.
  
  For all its partners, its customers and consumers, for its employees and all the communities Franke operates in. To achieve our goals, we will focus on four priorities: one brand, one planet, one culture, and one strategy.`
}

const goals = {
  title: "GOALS",
  nextBtn: "What's next?",
  text: "Let's open up a world of opportunities together."
}

export { intro, outro, journey, goals }