import styles from "./Modals.module.scss"
import ReactPlayer from 'react-player'

function ModalVideoCulture() {
  return <div className={`
    ${styles.wrap}
    ${styles.wrapVideo} 
  `}>
    <ReactPlayer 
      url="/videos/culture.mp4" 
      width="960px"
      height="540px"
      controls
      playing
    />
  </div>
}

export default ModalVideoCulture;