import IconLink from "components/IconLink/IconLink"
import Tooltip from "components/TooltipUI/TooltipUI"
import React, { useState } from "react"
import styles from "./Links.module.scss"

function Links({ list, className = '', iconAlign, size = "" }) {
  const [tooltips, setTooltips] = useState([])
  
  function removeTooltip(name) {
    const i = tooltips.indexOf(name)
    const newTooltips = [...tooltips] 
    newTooltips.splice(i, 1)
    console.log(i, [...newTooltips])
    setTooltips([...newTooltips])
  }

  function onToggle(link) {
    return () => {
      const i = tooltips.indexOf(link.tooltipName)
      if (i !== -1) {
        removeTooltip(link.tooltipName)
      } else {
        setTooltips([...tooltips, link.tooltipName])
      }
    }
  }

  return <div className={`${styles.links} ${className} ${size === 'large' ? styles.linksLarge : ''}`}>
    {list.map((link, i) => {
      const linkProps = {}
      if (link.tooltip) linkProps.onClick = onToggle(link);
      if (link.to) linkProps.to = link.to;
      const tooltipActive = link.tooltip && (tooltips.indexOf(link.tooltipName) !== -1)
      return <div className={styles.wrap} key={i}>
        <IconLink
          iconAlign={iconAlign}
          {...linkProps}
          className={styles.link}
          label={link.label} 
          icon={link.icon} 
          highlightIcon={tooltipActive}
        />
        {tooltipActive && 
          <Tooltip name={link.tooltipName} onClose={() => removeTooltip(link.tooltipName)}>
            {link.tooltip}
          </Tooltip>
        }
      </div>
    })}
  </div>
}

export default Links