import Icon from 'components/Icon/Icon';
import IconLink from 'components/IconLink/IconLink';
import { Link } from 'react-router-dom';
import styles from "./One.module.scss"
import Curious from 'assets/images/icons/culture-icons/curious.png'
import Ownership from 'assets/images/icons/culture-icons/ownership.png'
import Responsible from 'assets/images/icons/culture-icons/responsible.png'
import { ReactComponent as Solutions } from 'assets/images/icons/value/solutions.svg'
import Together from 'assets/images/icons/culture-icons/together.png'
import { useDispatch } from 'react-redux';
import { togglePDFModal, toggleVideoModal } from 'store/slices/ui';
import { MODAL_TYPES } from 'config/modals';

function HighlightItem({ title, text, icon }) {
  return <li className={styles.highlightItem}>
    <span className={styles.highlightItemIcon}>{icon}</span>
    <div className={styles.highlightItemContent}>
      <p className={styles.highlightItemTitle}>{title}</p>
      <span className={styles.highlightItemText}>{text}</span>
    </div>
  </li>
}
function Highlights() {
  return <ul className={styles.highlights}>
    <p className={styles.highlightsTitle}>Our Values</p>
    <HighlightItem title="WE DELIVER OUTSTANDING SOLUTIONS" 
      text="means we put customers first and focus on solutions." 
      icon={<Solutions />} />
    <HighlightItem title="WE WIN TOGETHER" 
      text="by collaborating across boundaries and empowering each other." 
      icon={<img src={Together} alt="" className={styles.highlightPngIcon} />} />
    <HighlightItem title="WE ARE CURIOUS" 
      text="translates into challenging the status quo and developing ourselves and each other." 
      icon={<img src={Curious} alt="" className={styles.highlightPngIcon} />} />
    <HighlightItem title="WE TAKE OWNERSHIP" 
      text="means we are accountable for our work and are proactive." 
      icon={<img src={Ownership} alt="" className={styles.highlightPngIcon} />} />
    <HighlightItem title="WE ACT RESPONSIBLY" 
      text="by caring for people and planet and valuing differences." 
      icon={<img src={Responsible} alt="" className={styles.highlightPngIcon} />} />
  </ul>
}

function Culture() {
  const dispatch = useDispatch();

  return (
    <div className={`${styles.wrap} ${styles.wrapCulture}`}>
      <div className={styles.leftCol}>
        <h5 className={`${styles.title} ${styles.titleCulture}`}>
          <Link to="/app/home" className={styles.back}>
            <Icon type="arrow-left" />
          </Link>
          <span>ONE CULTURE</span>
        </h5>
        
        <p className={styles.text}>
          As part of our One Franke Journey, we are committed to living a culture of delivering outstanding solutions, winning together, being curious, taking ownership, and acting responsibly.
        </p>
        <h6 className={`${styles.subTitle} ${styles.subTitleCulture}`}>WHY?</h6>
        <p className={styles.text}>
          Becoming One Franke requires more than aligned processes and systems. It requires a common culture with aligned values and behaviors.
        </p>
        <p className={styles.text}>
          Moreover, living our values means living our brand, because every interaction with our customers can either weaken or strengthen our brand.
        </p>
        <p className={styles.text}>
          And last but not least, in a world where products and business models face the constant threat of being replicated, we have to adapt to changing conditions and find new ways toward success.
        </p>
        <p className={styles.text}>
          That is why we have updated our values; they describe how we do what we do.
        </p>
      </div>

      <div className={styles.rightCol}>
        <Highlights />

        <div className={styles.links}>
          <span className={styles.linksTitle}>Have a look at the following material:</span>
          <div className={styles.linksContainer}>
            <span className={styles.linksCol}>
              <IconLink icon={"play"} label="One Culture Video" size="small" onClick={() => {
                dispatch(toggleVideoModal({ state: true, type: "culture" }))
              }} />
              <IconLink icon={"doc"} label="Employee Journey Map" size="small" onClick={() => {
                dispatch(togglePDFModal({ state: true, type: MODAL_TYPES.employee_journeymap }))
              }} />
            </span>
            <span className={`${styles.linksCol} ${styles.linksColLeft}`}>
              <IconLink icon={"doc"} label="One Culture Poster" size="small" onClick={() => {
                dispatch(togglePDFModal({ state: true, type: MODAL_TYPES.culture }))
              }} />
              <IconLink icon={"doc"} label="Value and Behavior Brochure" size="small" onClick={() => {
                dispatch(togglePDFModal({ state: true, type: MODAL_TYPES.value_behaviour }))
              }} />
              
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Culture