import styles from "./Modals.module.scss"
import ReactPlayer from 'react-player'

function ModalVideoImageMovie() {
  return <div className={`
    ${styles.wrap}
    ${styles.wrapVideo} 
  `}>
    <ReactPlayer 
      url="/videos/en_124s_FRANKE_Unterhmensfilm_-2dB_1920x1080_H264_AAC.mp4" 
      width="960px"
      height="540px"
      controls
      playing
    />
  </div>
}

export default ModalVideoImageMovie;