import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Brand from "./Brand/Brand";
import Culture from "./Culture/Culture";
import Planet from "./Planet/Planet";
import styles from "./Sections.module.scss";
import Strategy from "./Strategy/Strategy";
import { CSSTransition } from 'react-transition-group';

function Sections({ onEnter, onExit }) {
  const [activeSection, setActiveSection] = useState('')
  const params = useParams()
  const activeSectionRef = useRef(params.activeSection)
  const [sectionExited, setSectionExited] = useState('')

  useEffect(() => {
    if (params.section) {
      setActiveSection(params.section)
      setSectionExited('')
    } else {
      setActiveSection('')
      if (activeSectionRef.current) {
        setSectionExited(activeSectionRef.current)
      }
    }
    activeSectionRef.current = params.section
  }, [params.section])

  const brandIn = (!activeSection || activeSection === 'brand')

  return (
    <div className={styles.wrap}>
      <CSSTransition  
        in={brandIn}
        timeout={!brandIn ? 1000 : 2000}
        classNames="brand"
        //unmountOnExit
        >
        <Brand animatedIn={activeSection === 'brand'} animatedOut={sectionExited === 'brand'} />
      </CSSTransition>
        
      <CSSTransition  
        in={(!activeSection || activeSection === 'planet')}
        timeout={1500}
        classNames="planet"
        //unmountOnExit
      >
        <Planet animatedIn={activeSection === 'planet'} animatedOut={sectionExited === 'planet'} />
      </CSSTransition>
      
      <CSSTransition  
        in={(!activeSection || activeSection === 'culture')}
        timeout={1500}
        classNames="culture"
        //unmountOnExit
        onEnter={() => onEnter()}
        onExited={() => onExit()}>
        <Culture animatedIn={activeSection === 'culture'} animatedOut={sectionExited === 'culture'} />
      </CSSTransition>

      <CSSTransition  
        in={(!activeSection || activeSection === 'strategy')}
        timeout={1500}
        classNames="strategy"
        //unmountOnExit
        onEnter={() => onEnter()}
        onExited={() => onExit()}>
        <Strategy animatedIn={activeSection === 'strategy'} animatedOut={sectionExited === 'strategy'} />
      </CSSTransition>
    </div>
  );
}

export default Sections;