const milestones = {
  "2014_1": {
    title: "Launch of Make it Wonderful",
    text: ["2014", "Launch of the brand campaign 'Make it wonderful' and brand design guideline. Major milestone in making the Franke brand more emotional and relatable."]
  },

  "2020": {
    title: "Merger of Faber and Kitchen Systems to Home Solutions",
    text: ["2020",
`To combine and consolidate our core competencies in the areas of food preparation, cooking and air purification for domestic kitchens, Franke has decided to merge the two divisions Faber Hoods & Cooking Systems and Franke Kitchen Systems.`
]
  },

  "2021": {
    title: "Divestment of Franke Water Systems",
    text: ["2021", "To focus more on our core competencies and businesses where we are global leaders, Franke has decided to divest Water Systems."]
  },


//2022
  "2022_strategy": {
    title: "Start: Sirius Program",
    text: [
      "January 2022",
`Embark on a continuous improvement journey to harmonize and simplify our processes and systems.
Goal: enables us to focus on activities that are most meaningful and become more efficient as an organization.`
    ]
  },

  "2022_culture": {
    title: "Launch of the new Culture and Stage 1 of the One Franke Journey",
    text: ['May 2022',
`Launch of the new culture and first stage of our journey towards One Franke. Focus for stage 1 are the following values: 
- We win together
- We act responsibly
`]
  },

  "2022_planet_1": {
    title: "First Life-Cycle Measurement of our Emissions",
    text: ['September 2022', 'First measurement of our emissions in our entire value chain. Detection of priority areas in our value chain to focus on in the coming years. ']
  },

  "2022_planet_2": {
    title: "Completion of first Year with CDP",
    text: ["December 2022",
`Completed the first engagement cycle of our suppliers together with CDP.
Collected first primary data for our indirect emissions related to our suppliers.`]
  },

  
  "2022_brand": {
    title: "Start: Successive Launch of the Rebranding",
    text: ['September 2022', 
`Successive launch of our rebranding and start of the external communication.
Launch of our new Franke group webpages that reflect our new brand.`
]
  },

  
//2023
  "2023_strategy": {
    title: "Preparation and Execution of the Transition to SAP S/4HANA",
    text: ['January 2023', 
`Together with the implementation partner, continue to harmonize and enhance globally aligned processes and systems.
Preparation for transition to SAP S/4HANA - our new enterprise resource planning (ERP) system.
`
    ]
  },

  "2023_culture": {
    title: "Launch of Stage 2 of the One Franke Journey",
    text: ['January 2023',
`Launch of the second stage of our journey towards One Franke. Focus for stage 2 are the following values: 
- We deliver outstanding solutions
- We are curious
- We take ownership`
    ]
  },

  "2023_planet": {
    title: "Mid-term targets for our Emissions in our entire Value Chain",
    text: [
      'July 2023', 
      `Based on the first measurement of our life-cycle emissions, launch our mid-term targets and concrete roadmap towards net zero in 2040.`
    ]
  },

// 2024
"2024_strategy": {
  title: "Go Live SAP S/4HANA",
  text: ["August 2024", 
`Launch of the new SAP S/4HANA system as a core building block of the One Franke Journey.
Continuous transition of processes and systems to SAP S/4HANA.
`]
},
  "2024_culture": {
    title: "Continuous Development of our Culture",
    text: ["January 2024", 
`Continuously working on our culture and translating them into behaviors.
Further integrating our culture into our organization and living it in our everyday lives.`
]
  },
}

export default milestones