import { ReactComponent as Arrow } from 'assets/images/icons/icon/arrow.svg'
import { ReactComponent as Plus } from 'assets/images/icons/icon/plus.svg'
import { ReactComponent as Plus2 } from 'assets/images/icons/plus2.svg'
import { ReactComponent as Play } from 'assets/images/icons/icon/play.svg'
import { ReactComponent as Document } from 'assets/images/icons/icon/doc.svg'
import { ReactComponent as Close } from 'assets/images/icons/icon/close.svg'
import { ReactComponent as Download }  from 'assets/images/icons/download.svg'
import { ReactComponent as Check }  from 'assets/images/icons/check.svg'

import styles from "./Icon.module.scss"

function ArrowLeft(props) {
  return <Arrow {...props} style={{ transform: 'rotate(180deg)' }} />
}

const ICONS = {
  "arrow": Arrow,
  "arrow-left": ArrowLeft,
  "plus": Plus,
  "plus2": Plus2,
  "play": Play,
  "doc": Document,
  "close": Close,
  "download": Download,
  "check": Check
}

function Icon({ type, className = '', highlight }) {
  if (!ICONS[type]) return null;
  const Component = ICONS[type];

  const classList = `
    ${styles.icon} 
    ${styles[`icon_${type}`]} 
    ${className}
    ${highlight ? styles.iconHighlight : ''}
  `

  return <Component className={`${styles.icon} ${styles[`icon_${type}`]} ${classList}`} />
}

export default Icon;
