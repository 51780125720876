import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  status: '',
  error: null,
  videoModal: {
    state: false,
  },
  sectionModal: {
    state: false, 
    type: null
  } 
}

export const ui = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleVideoModal(state, action) {
      state.videoModal = action.payload
    },
    togglePDFModal(state, action) {
      state.sectionModal = action.payload
    },
  },
})


const { toggleVideoModal, togglePDFModal } = ui.actions
export default ui.reducer
export { toggleVideoModal, togglePDFModal }