import Icon from 'components/Icon/Icon';
import IconLink from 'components/IconLink/IconLink';
import { MODAL_TYPES } from 'config/modals';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { togglePDFModal, toggleVideoModal } from 'store/slices/ui';
import styles from "./One.module.scss"

function Brand() {
  const dispatch = useDispatch();

  return (
    <div className={`${styles.wrap} ${styles.wrapBrand}`}>
      <div className={styles.leftCol}>
      
        <h5 className={`${styles.title} ${styles.titleBrand}`}>
          <Link to="/app/home" className={styles.back}>
            <Icon type="arrow-left" />
          </Link>
          <span>ONE BRAND</span>
        </h5>

        <p className={styles.text}>Franke integrates its brands under one umbrella. On top, Franke has given itself a brand new look and a brand new identity.</p>
        <h6 className={styles.subTitle}>ACT</h6>
        <p className={styles.text}>Franke combines tradition with an innovative, adaptive and daring spirit - which is why it is both reassuring and exciting. This is our guiding principle that shapes the way we present ourselves  and the way we behave. See „One Culture” for more information on how we act.</p>

        <h6 className={styles.subTitle}>SPEAK</h6>
        <p className={styles.text}>Franke uses a brand voice - the personal coach - that gives us a clear idea of the way we communicate. It helps us to establish a stronger relationship with our audiences and supports us to act according to our values. We scout, we challenge, we enlighten and we instruct. </p>
      </div>

      <div className={styles.rightCol}>
        <h6 className={styles.subTitle}>LOOK</h6>
        <p className={styles.text}>Franke's principle of “reassuring and exciting” translates into clear icons, colors, lines and symbols. On top, we have slightly adjusted our logo and created new F-logos called favicons.</p>

        <div className={styles.links}>
          <span className={styles.linksTitle}>Have a look at the following material:</span>
          <div className={styles.linksContainer}>
            <div className={styles.linksCol}>
              <IconLink icon={"play"} label="Image Movie (External launch: 09/10.22)" size="small" onClick={() => {
                dispatch(toggleVideoModal({ state: true, type: "image-movie" }))
              }} />
              <IconLink icon={"play"} label="One Brand Video - coming soon" size="small" onClick={() => {}} />
            </div>
            <span className={`${styles.linksCol} ${styles.linksColLeft}`}>
              <IconLink icon={"doc"} label="One Brand Poster" size="small" onClick={() => {
                dispatch(togglePDFModal({ state: true, type: MODAL_TYPES.brand }))
              }} />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Brand