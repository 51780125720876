import Icon from "components/Icon/Icon";
import styles from "./TooltipUI.module.scss"

function Tooltip({ children, name = "", onClose, showBg = true, inverseTooltip = false }) {
  return <div className={`${styles.tooltip} ${name} ${inverseTooltip ? styles.tooltipInverse : ''}`}>
    {showBg && <span className={styles.bg} onClick={(e) => {
      onClose()
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    }} />}

    <div className={styles.tooltipContainer}>
      {onClose && <span className={styles.close} onClick={(e) => {
        onClose()
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
      }}><Icon type="close" /></span>}
      {children}
    </div>
  </div>
}

export default Tooltip;