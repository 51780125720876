import { ReactComponent as RightSide } from "assets/images/main_middle/brand/mid_brand_responsive_right.svg";
import { ReactComponent as LeftSide } from "assets/images/main_middle/brand/mid_brand_responsive_left.svg";
import * as planeAnimation from "assets/animations/mid_brand_plane.json"
import * as umbrellaAnimation from "assets/animations/mid_brand_umbrella_2.json"
import rootStyles from "../Sections.module.scss"
import styles from "./Brand.module.scss"
import LottiePlayer from "../LottiePlayer";
import { useDispatch, useSelector } from "react-redux";
import { completeAnimation, ANIMATION_TYPES } from "store/slices/animations";


function Brand({ animatedIn, animatedOut }) {
  const activeAnimation = useSelector(state => state.animations.activeAnimation)
  const dispatch = useDispatch()
  
  return (
    <div className={`
      ${rootStyles.section} 
      ${rootStyles.sectionBrand} 
      ${styles.container}
      ${animatedIn ? styles.animateIn : ''}
      ${animatedOut ? styles.animateOut : ''}
    `}>
      <LeftSide className={styles.leftSide} />
      <RightSide className={styles.rightSide} />
      
      <LottiePlayer animationData={umbrellaAnimation} 
        className={styles.umbrellaAnimation} 
        width={350} 
        height={350}
        onLoopComplete={() => {
          if (!animatedIn) dispatch(completeAnimation(ANIMATION_TYPES.umbrella))
        }}
        loopsNumber={1}
        playing={activeAnimation.indexOf(ANIMATION_TYPES.umbrella) !== -1 || animatedIn}
      />

      <LottiePlayer animationData={planeAnimation} 
        className={styles.planeAnimation} 
        width={600}
        height={600} 
        onLoopComplete={() => {
          if (!animatedIn) dispatch(completeAnimation(ANIMATION_TYPES.plane))
        }}
        playing={activeAnimation.indexOf(ANIMATION_TYPES.plane) !== -1 || animatedIn}
      />
    </div>
  );
}

export default Brand