import styles from "./Tooltips.module.scss"

function ImpressumTooltip() {
  return <div className={`${styles.wrap}`}>
    <h4 className={styles.title}>Impressum</h4>
    <p className={styles.text}>
    The website was developed by the Change &amp; Innovation team to bring the One Franke Journey to life. The team was supported by various employees in creating the content.
    </p>
    <p className={styles.text}>
      It is intended for internal use and will be updated regularly.
    </p>
    <p className={styles.text}>
      Launched in 2022.
    </p>
    <ul className={styles.list}>
      <li className={styles.listItem}>
        <span className={styles.listLabel}>IMPLEMENTATION</span>
        <a href="https://www.zense.ch" target="_blank" rel="noreferrer" className={styles.listLink}>Zense GmbH</a>
      </li>
      <li className={styles.listItem}>
        <span className={styles.listLabel}>CONTACT</span>
        <a href="mailto:sahin.yilmaz@franke.com" className={styles.listLink}>sahin.yilmaz@franke.com</a>
      </li>
    </ul>
  </div>
}

export default ImpressumTooltip;