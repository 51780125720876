import styles from "./Home.module.scss"
import SidebarJourney from 'components/SidebarJourney/SidebarJourney'
import SidebarGoals from 'components/SidebarGoals/SidebarGoals'
import MainArea from 'components/MainArea/MainArea'
import Milestones from 'components/Milestones/Milestones'

function Home() {
  return (<>
    <Milestones />
    <div className={styles.page}>
      <SidebarJourney />
      <MainArea />
      <SidebarGoals />
    </div>
    </>
  );
}

export default Home