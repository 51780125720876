import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './store'
import { Provider } from 'react-redux'
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";

const TENANT_ID = `bc3bbf01-f531-46bd-a22e-129fe76c0345`
const authority = `https://login.microsoftonline.com/${TENANT_ID}/`

const configuration = {
  auth: {
    clientId: "a598448d-531a-4b12-814f-a1eceee529ce",
    authority,
    knownAuthorities: [authority],
    redirectUri: `${window.location.origin}/login`
  }
};
const pca = new PublicClientApplication(configuration);

ReactDOM.render(
  <Provider store={store}>
    <MsalProvider instance={pca}>
      <App />
    </MsalProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
