const goals = {
  "1franke": {
    list: [ 
      { label: `Global alignment of our businesses, strengthening our common processes` },
      { label: `Globally align our brand universe under the Franke brand` },
      { label: `Introduce a common culture across regions and divisions and live it consistently` },
    ]
  },
  "profit": {
    list: [
      { label: `Grow profitability in our respective markets` },
      { label: `Increase our profitability and reach double-digit EBIT margins` },
    ]
  },
  "revenue": {
    list: [
      { label: `Focus on our leading business areas and grow them systematically` },
      { label: `Be one of the top 3 players in size in each of our divisional businesses` },
      { label: `Win market share and grow them more than the respective markets` },
    ]
  },
  "emissions": {
    list: [
      { 
        label: `Reach net zero by reducing and avoiding emissions`,
        subPoints: [
          `In 2030 when it comes to our production`,
          `In 2040 when it comes to our entire value chain`
        ]
      },
    ]
  },
  "accidents": {
    list: [
      {
        label: 'Franke aims to increase its workplace safety in all its locations and reduce the yearly number of accidents to zero.'
      }
    ]
  }
}

export default goals