import Icon from 'components/Icon/Icon';
import { useEffect, useState } from 'react';
import { Page } from 'react-pdf';
import { Document } from 'react-pdf/dist/esm/entry.webpack';
import { useInView } from "react-intersection-observer";
import styles from "./PDFViewer.module.scss"

function PDFPage({ pageNumber, width, onInViewChange }) {
  const { ref, inView } = useInView({
    threshold: 0
  });

  useEffect(() => {
    onInViewChange(inView)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView])

  return <div ref={ref} className={styles.pageWrap}><Page pageNumber={pageNumber} width={width} /></div>;
}

function PDFViewer({ url, parentRef }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [width, setWidth] = useState(null)
  const [inViewPages, setInViewPages] = useState([])

  useEffect(() => {
    if (parentRef.current) {
      setWidth(parentRef.current.offsetWidth)
    }
  }, [parentRef])
  
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  useEffect(() => {
    setPageNumber(inViewPages.indexOf(true) + 1)
  }, [inViewPages])

  return (width ? 
    <div className={styles.wrap}>
      <Document file={url} 
        onLoadSuccess={onDocumentLoadSuccess}>
        {(() => {
          if (!numPages) return null;
          let res = []
          for (let i = 1; i <= numPages; i++) {
            res.push(<PDFPage 
              pageNumber={i} 
              width={width}
              onInViewChange={(v) => {
                const inview = [...inViewPages]
                inview[i-1] = v
                setInViewPages(inview)
              }} />)
          }
          return res;
        })()}
      </Document>
      <div className={styles.controls}>
        <span>Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}</span>
        <a href={url} target="_blank" rel="noreferrer" className={styles.download}><Icon type="download" /></a>
      </div>
    </div> 
  : null)
}

export default PDFViewer;