import Icon from 'components/Icon/Icon';
import IconLink from 'components/IconLink/IconLink';
import { Link } from 'react-router-dom';
import styles from "./One.module.scss"
import { useDispatch } from 'react-redux';
import { togglePDFModal } from 'store/slices/ui';
import { MODAL_TYPES } from 'config/modals';

function Planet() {
  const dispatch = useDispatch()
  return (
    <div className={`${styles.wrap} ${styles.wrapPlanet}`}>
      <div className={styles.leftCol}>
        <h5 className={`${styles.title} ${styles.titlePlanet}`}>
          <Link to="/app/home" className={styles.back}>
            <Icon type="arrow-left" />
          </Link>
          <span>ONE PLANET</span>
        </h5>

        <p className={styles.text}>
          Franke follows a clear sustainability strategy and
          fully integrates societal and environmental care in
          the entire value chain.
        </p>
        <h6 className={`${styles.subTitle} ${styles.subTitlePlanet}`}>ENVIRONMENT</h6>
        <p className={styles.text}>
          By reducing and removing emissions, we want to reach net zero emissions by 2030 when it comes to production, and by 2040 when it comes to the organization's entire value chain.
        </p>

        <h6 className={`${styles.subTitle} ${styles.subTitlePlanet}`}>EMPLOYEES</h6>
        <p className={styles.text}>
          Franke wants to provide a work environment that is engaging and inclusive - one that empowers its diverse employees to learn and do their best work every day.
        </p>
      </div>

      <div className={styles.rightCol}>
        <h6 className={`${styles.subTitle} ${styles.subTitlePlanet}`}>SOCIETY</h6>
        <p className={styles.text}>
          Franke wants to make significant contributions to key societal needs and the well-being of communities. To do so, it focuses on partnerships, volunteering and donations.
        </p>

        <div className={styles.links}>
          <span className={styles.linksTitle}>Have a look at the following material:</span>
          <div className={styles.linksContainer}>
            <div className={styles.linksCol}>
              <IconLink icon={"play"} label="One Planet Video - coming soon" size="small" onClick={() => {}} />
            </div>

            <span className={`${styles.linksCol} ${styles.linksColLeft}`}>
              <IconLink icon={"doc"} label="One Planet Poster" size="small" onClick={() => {
                dispatch(togglePDFModal({ state: true, type: MODAL_TYPES.planet }))
              }} />
              <IconLink icon={"doc"} label="Sustainability Report 2020" size="small" onClick={() => {
                dispatch(togglePDFModal({ state: true, type: MODAL_TYPES.sustainability }))
              }} />
              <IconLink icon={"doc"} label="Sustainability Report 2021 - coming soon" size="small" onClick={() => {
                //dispatch(togglePDFModal({ state: true, type: MODAL_TYPES.sustainability }))
              }} />
              
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Planet