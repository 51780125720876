import Icon from "components/Icon/Icon";
import styles from "./ModalUI.module.scss"

function Modal({ children, name = "", onClose, modalType = '' }) {
  return <div className={`${styles.modal} ${name} ${styles[modalType]}`}>
    <span className={styles.bg} onClick={(e) => {
      onClose()
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    }} />
    <div className={styles.modalContainer}>
      <span className={styles.close} onClick={(e) => {
        onClose()
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
      }}><Icon type="close" /></span>
      <div className={styles.content}>{children}</div>
    </div>
  </div>
}

export default Modal;