import { ReactComponent as Range } from 'assets/images/progress.svg'
import { ReactComponent as Range2 } from 'assets/images/progress2.svg'
import styles from "./GoalItem.module.scss"
import Icon from 'components/Icon/Icon'
import { useState } from 'react';
import Tooltip from 'components/TooltipUI/TooltipUI';

function GoalItem({ icon, label, tooltip, showPlus = true, isEmissions = false }) {
  const [tooltipActive, setTooltipActive] = useState(false)
  return (
    <div className={`${styles.wrap} ${isEmissions ? styles.wrapEmissions : ''}`}>
      <span className={styles.icon}>{icon}</span>
      <span className={styles.range}>
        {/*<span className={styles.year}>
          {!isEmissions ? "2025" : `2030       2040`}
        </span>*/}
        {!isEmissions ? <Range /> : <Range2 />}
        <span className={styles.label}>{label}</span>
      </span>
      {showPlus && <span onClick={() => setTooltipActive(true)} className={styles.plusWrap}>
        <Icon type="plus" className={styles.plus} highlight={tooltipActive} />
        {tooltipActive && <Tooltip name={"tooltipGoal"} onClose={(e) => {
          setTooltipActive(false)
        }}>
          {tooltip}
        </Tooltip>}
      </span>}
    </div>
  );
}

export default GoalItem