import PDFViewer from 'components/PDFViewer/PDFViewer';
import { MODALS } from 'config/modals';
import { useRef } from 'react';
import styles from "./Modals.module.scss"

function ModalPDF({ type }) {
  const url = MODALS[type]
  const ref = useRef()
  return <div className={styles.wrap} ref={ref}>
    <PDFViewer url={url} parentRef={ref} />
  </div>
}

export default ModalPDF;