import styles from "./SectionsList.module.scss"
import { ReactComponent as Brand } from "assets/images/icons/one/brand.svg"
import { ReactComponent as Culture } from "assets/images/icons/one/culture.svg"
import { ReactComponent as Planet } from "assets/images/icons/one/planet.svg"
import { ReactComponent as Strategy } from "assets/images/icons/one/strategy.svg"
import Icon from "components/Icon/Icon"
import { Link, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { CSSTransition } from "react-transition-group"

function SectionItem({ icon, label, className, to }) {
  return <Link className={`${styles.item} ${className}`} to={to}>
    <span className={styles.iconWrap}>{icon}</span>
    <span className={styles.itemLabel}>{label}</span>
    <Icon type="arrow" className={styles.arrowIcon} />
  </Link>
}

function SectionsList() {
  const params = useParams();
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    if (params.section && visible) setVisible(false);
    if (!params.section && !visible) setVisible(true);
  }, [params, visible])

  return ( <CSSTransition
    in={!params.section}
    timeout={2000}
    classNames="sectionsList"
    unmountOnExit
    >
    <div className={styles.wrap}>
      <SectionItem label="One Brand" 
        icon={<Brand className={styles.icon} />} 
        className={styles.itemBrand} 
        to={'/app/one/brand'}
      />
      <SectionItem label="One Planet" 
        icon={<Planet className={styles.icon} />} 
        className={styles.itemPlanet} 
        to={'/app/one/planet'}
      />
      <SectionItem label="One Culture"  
        icon={<Culture className={styles.icon} />} 
        className={styles.itemCulture} 
        to={'/app/one/culture'}
      />
      <SectionItem label="One Strategy" 
        icon={<Strategy className={styles.icon} />} 
        className={styles.itemStrategy} 
        to={'/app/one/strategy'}
      />
    </div>
    </CSSTransition>
  );
}

export default SectionsList